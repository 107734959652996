<template>
	<banner-style1 :position="2"></banner-style1>
	<div class="event-dynamic ul-none">
		<div class="dynamic-container">
			<div class="content-tabs c w">
				<ul>
					<li v-for="(item, index) in tabList" :key="item.id">
						<span :class="{ on: tabCurrent === index }" @click="tabClick(index)">{{ item.name }}</span>
					</li>
				</ul>
				<component :is="currentComponet.com" @liCick="liCick" @currentChange="currentChange"
					:tab-id="tabList[tabCurrent].value" :html="html" :listdata="listdata"></component>
			</div>
		</div>
	</div>
</template>

<script setup>
import {
	reactive,
	ref,
	markRaw
} from 'vue'
import {
	useRoute
} from 'vue-router'
import dynamicContent from './dynamic-content/dynamicContent'
import dynamicDetail from './dynamic-detail/dynamicDetail'

import {
	getList,
	getContentInfo
} from '@/api/competition'

let route = useRoute()
let listdata = ref('')
const tabCurrent = ref(0)
const tabList = reactive([{
	id: 1,
	name: '赛事公告',
	value: 5
}, {
	id: 2,
	name: '赛事新闻',
	value: 3
}, {
	id: 3,
	name: '赛事相册',
	value: 7
}, {
	id: 4,
	name: '赛事视频',
	value: 6
}])
getList({
	type: tabList[tabCurrent.value].value
}).then(res => {
	listdata.value = res.data
})
function currentChange(e) {
	getList({
		type: tabList[tabCurrent.value].value,
		page: e
	}).then(res => {
		listdata.value = res.data
	})
}
function tabClick(i) {
	tabCurrent.value = i
	getList({
		type: tabList[tabCurrent.value].value
	}).then(res => {
		listdata.value = res.data
	})
	currentComponet.com = tabContent[0].com
}
let tabContent = reactive([{
	name: '列表',
	com: markRaw(dynamicContent)
},
{
	name: '详情',
	com: markRaw(dynamicDetail)
},
]);
const currentComponet = reactive({
	com: tabContent[0].com
})
let html = ref('')

if (route.query.id) {
	currentComponet.com = tabContent[1].com
	getContentInfo({
		id: route.query.id
	}).then(res => {
		html.value = res.data
	})
} else {
	currentComponet.com = tabContent[0].com
}

function liCick(item, i) {
	html.value = item
	currentComponet.com = tabContent[1].com
}
</script>

<style lang="scss" scoped>
.dynamic-container {
	margin: 40px;

	.content-tabs {

		display: flex;

		ul {
			min-width: 120px;
		}

		li {
			margin-bottom: 10px;

			span {
				display: inline-block;
				padding: 6px 0;
				cursor: pointer;
				font-weight: 700;
				box-sizing: border-box;
			}

			.on {
				color: #59af98;
				border-bottom: 2px solid #59af98;

			}
		}

	}
}
</style>

<template>
	<div class="dynamic-content-list ul-none">
		<ul>
			<li @click="handleClick(item, i)" v-for="item in listdata.data" :key="item.id">
				<div class="d-img">
					<img :src="item.thumbnail" alt="">
				</div>
				<div class="d-con">
					<div class="text">
						<p class="time">{{ item.createTime }}</p>
						<h2>{{ item.title }}</h2>
						<p class="desc">{{ item.describe }}</p>
					</div>
					<el-icon class="arrow-right" size="30px">
						<ArrowRight />
					</el-icon>
				</div>
			</li>
		</ul>
		<div class="page-con">
			<el-pagination background @current-change="currentChange" :page-size="10" :pager-count="5"
				layout="prev, pager, next" :total="listdata.total" />
		</div>
	</div>
</template>

<script setup>
import {
	defineEmits,
	defineProps,
	reactive
} from 'vue'

import {
	ArrowRight
} from '@element-plus/icons-vue'

const emit = defineEmits(['liCick','currentChange'])
const props = defineProps({
	// 'tabId': {
	// 	type: Number
	// },
	listdata: {
		type: Object,
		default() {
			return {}
		}
	}
})
console.log(props.listdata, 'listdata');

function handleClick(item, i) {
	emit('liCick', item, i)
}
const query = reactive({
	page: 1
})
function currentChange(e) {
	emit('currentChange', e)
}
</script>

<style lang="scss" scoped>
.dynamic-content-list {
	width: 100%;

	.d-img {
		width: 330px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	li {
		position: relative;
		display: flex;
		height: 140px;
		padding-bottom: 20px;
		border-bottom: 1px dashed #e5e5e5;
		cursor: pointer;

		.d-con {
			margin-left: 40px;
			flex: 1;

			.text {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-right: 40px;
			}

			.time {
				margin-bottom: 5px;
				color: #a0a0a1;
			}

			.desc {
				margin-top: 3px;
				color: #a0a0a1;
			}

			.arrow-right {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
			}
		}
	}
}

.page-con {
	float: right;
	margin: 40px 0 120px 0;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
	background-color: $themeColor !important; //修改默认的背景色
}
</style>
